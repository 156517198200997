<template lang="pug">
    v-app
        v-navigation-drawer.d-flex.justify-end.d-sm-flex.d-md-none(
            app
            left
            temporary
            v-model="isOpen"
            color="#87CEFA"
        )
            v-list(
                nav
                link
            )
                v-list-item-group
                    v-list-item(
                        v-for="route in routeList"
                        :key="route.routeName"
                        :to="route.to"
                        activeClass="blue--text text--lighten-1"
                    )
                        v-list-item-title.text-center.black--text(
                            :class="[route.className, { 'animate__animated animate__lightSpeedInLeft': isOpen }]"
                        ) {{ route.tabName }}
        v-app-bar(
            app
            light
            color="#87CEFA"
        )
            //- 行動裝置版
            .mobile.d-md-none
                routerLink(to="/")
                    img.py-2(
                        :src="logo"
                        width="70px"
                        height="70px"
                    )
                v-spacer
                v-app-bar-nav-icon(
                    x-large
                    @click="isOpen = true"
                )

            //- PC版
            .pc.d-none.d-sm-none.d-md-flex.d-lg-flex.justify-md-space-between.justify-lg-space-between.align-center
                div
                    routerLink.d-flex.align-center(to="/")
                        img.py-2(
                            :src="logo"
                            width="80px"
                            height="80px"
                        )
                div
                    v-tabs(
                        v-model="tabName"
                        backgroundColor="#87CEFA"
                    )
                        v-tabs-slider.d-none
                        v-hover(
                            v-for="route in routeList"
                            :key="route.routeName"
                            v-slot="{ hover }"
                        )
                            v-tab.px-5(
                                :to="route.to"
                                :class="{'animate__animated animate__rubberBand animate__faster': hover}"
                                activeClass="orange--text text--darken-3"
                            ) {{ route.tabName }}
        v-main
            v-container.pa-0.ma-0(fluid)
                router-view
        v-footer(
            dark
            padless
        )
            v-card(
                flat
                tile
                width="100%"
                backgroundColor="#87CEFA"
            )
                v-card-text
                    v-row.py-5
                        v-col(:cols="12")
                            v-row
                                v-col.d-flex.justify-center.align-center(
                                    :cols="12"
                                    :md="4"
                                )
                                    routerLink(to="/")
                                        img.footer-logo(:src="logoFooter")
                                v-col(
                                    :cols="12"
                                    :md="3"
                                )
                                    .text-h5.font-weight-bold.mb-3.grey--text About Us
                                    v-list(dense color="#87CEFA")
                                        v-list-item(
                                            v-for="(list, key) in footerAbout"
                                            :key="key"
                                            :href="list.to"
                                        )
                                            v-list-item-icon
                                                v-icon(
                                                    small
                                                    color="#EF6C00"
                                                ) fas fa-link
                                            v-list-item-content.text-body-2.black--text {{ list.title }}
                                v-col(
                                    :cols="12"
                                    :md="5"
                                )
                                    .text-h5.font-weight-bold.grey--text.mb-3 Contact Info
                                    v-list(dense color="#87CEFA")
                                        v-list-item(
                                            v-for="(list, key) in footerLeftContact"
                                            :key="key"
                                            :to="list.to"
                                        )
                                            v-list-item-icon
                                                v-icon(
                                                    small
                                                    color="#EF6C00"
                                                ) {{ list.icon }}
                                            v-list-item-content.text-body-2.black--text
                                                a.simple(
                                                    v-if="href_info[key]"
                                                    :href="href_info[key]"
                                                ) {{ list.label }}
                                                span(v-else) {{ list.label }}
                                        //- v-col(
                                        //-     :cols="12"
                                        //-     :md="6"
                                        //- )
                                        //-     v-list(dense color="#87CEFA")
                                        //-         v-list-item(
                                        //-             v-for="(list, key) in footerRightContact"
                                        //-             :key="key"
                                        //-             :to="list.to"
                                        //-         )
                                        //-             v-list-item-icon
                                        //-                 v-icon(
                                        //-                     small
                                        //-                     color="#EF6C00"
                                        //-                 ) fa fa-city
                                        //-             v-list-item-content.text-body-2.black--text
                                        //-                 a.simple(
                                        //-                     v-if="href_info[key]"
                                        //-                     :href="href_info[key]"
                                        //-                 ) {{ list }}
                                        //-                 span(v-else) {{ list }}
                v-divider(color="white")
                v-card-text.text-center.black--text &copy; {{ new Date().getFullYear() }} BRISK Services Pte Ltd - Design by &nbsp;
                    a.leyu(
                        href="https://leyutechs.com/"
                        target="_blank"
                    ) 樂宇創意科技有限公司
</template>

<script>
// import logo from "@/assets/logo-white.png";
// import logoFooter from '@/assets/logo-footer.png';

import logo from '@/assets/brisk/logo.png';
import logoFooter from '@/assets/brisk/logo.png';


export default {
    data() {
        return {
            logo,
            logoFooter,
            isOpen: false,
            tabName: "home",
        };
    },
    computed: {
        routeList: {
            get: () => [
                {
                    to: "/",
                    className: "home",
                    routeName: "home",
                    tabName: "Home",
                },
                {
                    to: "about",
                    className: "about",
                    routeName: "about",
                    tabName: "Brisk - About Us",
                },
                {
                    to: "assemblyProcess",
                    className: "assembly-process",
                    routeName: "assemblyProcess",
                    tabName: "Core Services",
                },
                {
                    to: "actualCase",
                    className: "actual-case",
                    routeName: "actualCase",
                    tabName: "Experiences",
                },
                {
                    to: "contact",
                    className: "contact",
                    routeName: "contact",
                    tabName: "Contact us",
                },
            ],
        },
        footerAbout: {
            get: () => ({
                companyInfo: {
                    to: "/about#companyInfo",
                    title: "Company Profile",
                },
                feature: {
                    to: "/about#feature",
                    title: "Feature Outlook",
                },
                assemblyProcess: {
                    to: "/assemblyProcess",
                    title: "Core Services",
                },
                actualCase: {
                    to: "/actualCase",
                    title: "Experiences",
                },
            }),
        },
        href_info: {
            get: () => ({
                phone: "tel:+65 6677 1122",
                cellphone_1: "tel:+65 9732 9333",
                cellphone_2: "tel:+65 8800 8088",
                email: "mailto:sales@brisks.com.sg",
            }),
        },
        footerLeftContact: {
            get: () => ({
                company: {
                    label: "BRISK SERVICES PTE LTD",
                    icon: 'fas fa-link'
                },
                phone: {
                    label: "TEL: +65 6677 1122 , +65 6677 5599",
                    icon: 'fas fa-phone-alt'
                },
                cellphone_1: {
                    label: "Ops 24/7: +65 9732 9333",
                    icon: 'fas fa-mobile-alt'
                },
                cellphone_2: {
                    label: "Project: +65 8800 8088",
                    icon: 'fas fa-folder-open'
                },
                fax: {
                    label: "Fax: +65 6262 1137",
                    icon: 'fas fa-fax'
                },
                email: {
                    label: "E-mail：sales@brisks.com.sg",
                    icon: 'fas fa-envelope'
                },
                address: {
                    label: "Address: 2 Buroh Crescent, ACE @ Buroh, #01-16 : Singapore 627546",
                    icon: 'fas fa-city'
                },
            }),
        },
        // footerRightContact: {
        //     get: () => ({
        //         company: "公司：company",
        //         phone: "電話：00-12345678",
        //         cellphone_1: "行動電話(1): 0912-345-678",
        //         cellphone_2: "行動電話(2): 0912-345-678",
        //         fax: "傳真：01-12345678",
        //         email: "E-mail：brisk@gmail.com",
        //         address: "地址：77 Happy Street, Happy Town, Happy District, Happy City",
        //     }),
        // },
    },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
    .home {
        --animate-duration: 0.6s;
    }
    .about {
        --animate-duration: 0.8s;
    }
    .construction-process {
        --animate-duration: 1s;
    }
    .actual-case {
        --animate-duration: 1.2s;
    }
    .contact {
        --animate-duration: 1.4s;
    }
}
header {
    height: 90px !important;
    display: block;
}
.v-toolbar::v-deep {
    .v-toolbar__content {
        height: 90px !important;
        font-weight: bold;

        .mobile,
        .pc {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        img {
            width: 95px;
            height: 90px;
        }
    }
}
.v-main::v-deep {
    overflow: hidden;
    width: 100%;
}
.v-footer {
    position: relative;
    width: 100%;
    overflow: hidden;

    .v-card__text {
        background-color: #87CEFA;
    }

    .footer-logo {
        height: 250px;
        width: 250px;
    }
    .leyu {
        word-break: break-all;
        display: inline-block;
        text-decoration: none;
    }
}

a.simple {
    text-decoration: none;
    color: inherit
}

a.simple:hover {
    text-decoration: underline;
    font-weight: bold;
    color: #1976d2;
}
</style>
