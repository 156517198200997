<template lang="pug">
    router-view
</template>

<script>
export default {
    name: "App",
    data: () => ({
        //
    }),
};
</script>

<style lang="scss">
@import '~@/styles/index.scss';

.row {
    padding: 0;
    margin: 0;
}
</style>
