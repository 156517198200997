import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '',
				name: 'Home',
				component: () => import('@/views/pages/Home.vue')
			},
			{
				path: 'about',
				name: 'About',
				component: () => import('@/views/pages/About.vue')
			},
			{
				path: 'assemblyProcess',
				name: 'AssemblyProcess',
				component: () => import('@/views/pages/CoreServices.vue')
			},
			{
				path: 'actualCase',
				name: 'ActualCase',
				component: () => import('@/views/pages/Experiences.vue')
			},
			{
				path: 'contact',
				name: 'Contact',
				component: () => import('@/views/pages/Contact.vue')
			}
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
				offset: {
					x: 0,
					y: 70
				}
			};
		}
		return { x: 0, y: 0 };
	}
});

export default router
